exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-services-custom-software-solutions-js": () => import("./../../../src/pages/services/custom-software-solutions.js" /* webpackChunkName: "component---src-pages-services-custom-software-solutions-js" */),
  "component---src-pages-services-drone-services-js": () => import("./../../../src/pages/services/drone-services.js" /* webpackChunkName: "component---src-pages-services-drone-services-js" */),
  "component---src-pages-services-freelancing-js": () => import("./../../../src/pages/services/freelancing.js" /* webpackChunkName: "component---src-pages-services-freelancing-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-mobile-apps-js": () => import("./../../../src/pages/services/mobile-apps.js" /* webpackChunkName: "component---src-pages-services-mobile-apps-js" */),
  "component---src-pages-services-responsive-web-design-js": () => import("./../../../src/pages/services/responsive-web-design.js" /* webpackChunkName: "component---src-pages-services-responsive-web-design-js" */),
  "component---src-pages-tech-js": () => import("./../../../src/pages/tech.js" /* webpackChunkName: "component---src-pages-tech-js" */),
  "component---src-templates-project-details-js": () => import("./../../../src/templates/project-details.js" /* webpackChunkName: "component---src-templates-project-details-js" */)
}


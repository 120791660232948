exports.onInitialClientRender = () => {
  console.log("\n" +
      "██╗░░██╗██╗████████╗███████╗██████╗░██╗████████╗\n" +
      "██║░██╔╝██║╚══██╔══╝╚════██║██╔══██╗██║╚══██╔══╝\n" +
      "█████═╝░██║░░░██║░░░░░███╔═╝██████╦╝██║░░░██║░░░\n" +
      "██╔═██╗░██║░░░██║░░░██╔══╝░░██╔══██╗██║░░░██║░░░\n" +
      "██║░╚██╗██║░░░██║░░░███████╗██████╦╝██║░░░██║░░░\n" +
      "╚═╝░░╚═╝╚═╝░░░╚═╝░░░╚══════╝╚═════╝░╚═╝░░░╚═╝░░░\n" +
      "\n" +
      "██╗███╗░░██╗███████╗░█████╗░██████╗░███╗░░░███╗░█████╗░████████╗██╗██╗░░██╗\n" +
      "██║████╗░██║██╔════╝██╔══██╗██╔══██╗████╗░████║██╔══██╗╚══██╔══╝██║██║░██╔╝\n" +
      "██║██╔██╗██║█████╗░░██║░░██║██████╔╝██╔████╔██║███████║░░░██║░░░██║█████═╝░\n" +
      "██║██║╚████║██╔══╝░░██║░░██║██╔══██╗██║╚██╔╝██║██╔══██║░░░██║░░░██║██╔═██╗░\n" +
      "██║██║░╚███║██║░░░░░╚█████╔╝██║░░██║██║░╚═╝░██║██║░░██║░░░██║░░░██║██║░╚██╗\n" +
      "╚═╝╚═╝░░╚══╝╚═╝░░░░░░╚════╝░╚═╝░░╚═╝╚═╝░░░░░╚═╝╚═╝░░╚═╝░░░╚═╝░░░╚═╝╚═╝░░╚═╝")
  document.getElementById("___gatsby").style.display = "block"
  document.getElementById("___loader").style.display = "none"
}
